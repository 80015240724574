<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>{{ $t('form.filters') }}</CCardHeader>
          <CCardBody>
            <CForm id="statistics-filter-form" @submit.prevent="onSubmit">
              <CRow>
                <CCol class="col-12 col-sm-4 col-xl-3">
                  <div class="form-group datepicker-wrap">
                    <label for="datepicker" class="col-form-label-sm datepicker">
                      {{ $t('form.dates') }}
                    </label>
                    <date-picker
                      v-model="dates"
                      :clearable="false"
                      :input-attr="{ id: 'datepicker' }"
                      range
                      format="DD.MM.YYYY"
                      range-separator=" - "
                      title-format="DD.MM.YYYY"
                      :class="{ 'is-invalid': isEmptyDate }"
                    ></date-picker>
                  </div>
                </CCol>

                <CCol class="col-12 col-sm-4 col-xl-3">
                  <CSelect :value.sync="filters.limit" size="sm" :label="$t('form.limits')" :options="limits" custom />
                </CCol>

                <CCol class="col-12 col-sm-4 col-xl-3">
                  <CSelect
                    :value.sync="filters.group_by"
                    size="sm"
                    :label="$t('form.group_by')"
                    :options="statisticsGroups"
                    custom
                  />
                </CCol>

                <CCol class="col-12 col-sm-4 col-xl-3">
                  <div class="form-group">
                    <label class="col-form-label-sm" for="site-id">{{ $t('form.sites') }}</label>
                    <multiselect
                      id="site-id"
                      v-model="filters.site_ids"
                      :placeholder="$t('form.sites')"
                      :options="siteList"
                      :multiple="true"
                      :show-labels="false"
                      track-by="value"
                      label="label"
                    >
                      <template #noResult>{{ $t('form.not_found_elements') }}</template>
                    </multiselect>
                  </div>
                </CCol>

                <CCol class="col-12 col-sm-4 col-xl-3">
                  <div class="form-group">
                    <label class="col-form-label-sm" for="block-id">{{ $t('form.blocks') }}</label>
                    <multiselect
                      id="block-id"
                      v-model="filters.widget_ids"
                      :placeholder="$t('form.blocks')"
                      :options="blockList"
                      :multiple="true"
                      :show-labels="false"
                      track-by="value"
                      label="label"
                    >
                      <template #noResult>{{ $t('form.not_found_elements') }}</template>
                    </multiselect>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <CButton
              form="statistics-filter-form"
              type="submit"
              :disabled="isLoading || isEmptyDate"
              size="sm"
              color="info"
            >
              {{ $t('form.filter') }}
            </CButton>

            <CButton
              form="statistics-filter-form"
              class="ml-2"
              type="button"
              size="sm"
              color="danger"
              :disabled="isLoading"
              @click="searchReset"
              >{{ $t('form.reset') }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>{{ $t('sidebar.statistics') }}</CCardHeader>
          <CCardBody>
            <MbnErrorMessage v-if="error" />

            <CDataTable
              v-else
              :fields="fields"
              :items="statisticsList"
              striped
              add-table-classes="section-table"
              :no-items-view="{ noItems: $t('ui.no_items_available') }"
              sorter
              hover
              :loading="isLoading"
            >
            </CDataTable>

            <CPagination
              v-if="isShowedPagination"
              :pages="totalPages"
              :active-page="filters.page"
              size="sm"
              @update:activePage="changePage"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import MbnErrorMessage from '@/components/ErrorMessage';
import { format } from 'date-fns';
import { limits, filtersDefaultLimit } from '@/helpers/const';
import { webmasterStatisticsGroups } from '@/helpers/statistic-groups';
import { mapState, mapGetters, mapActions } from 'vuex';
import { GET_WEBMASTER_STATISTICS } from '@/store/action-types/statistics';
import { GET_SITES_LIST } from '@/store/action-types/sites';
import { GET_BLOCKS_LIST } from '@/store/action-types/blocks';

export default {
  name: 'StatisticsWebmaster',
  components: {
    DatePicker,
    MbnErrorMessage,
  },
  data() {
    return {
      selectedLimit: this.$route.query.limit || limits[0].value,
      dates: [],
      filters: {
        limit: this.$route.query.limit || limits[0].value,
        group_by: this.$route.query.group_by || webmasterStatisticsGroups[0].value,
        date_start: this.$route.query.date_start || '',
        site_ids: this.$route.query.site_ids || [],
        widget_ids: this.$route.query.widget_ids || [],
        date_end: this.$route.query.date_end || '',
        page: this.$route.query.page || 1,
      },
    };
  },
  computed: {
    ...mapState({
      statisticsList: (state) => state.statistics.statisticsWebmaster.statistics,
      isLoading: (state) => state.statistics.statisticsWebmaster.isLoading,
      error: (state) => state.statistics.statisticsWebmaster.error,
      totalItems: (state) => state.statistics.statisticsWebmaster.total,
      roles: (state) => state.roles.roles,
    }),
    ...mapGetters('sites', ['siteList']),
    ...mapGetters('blocks', ['blockList']),
    totalPages() {
      return Math.ceil(this.totalItems / this.selectedLimit);
    },
    isShowedPagination() {
      return this.totalPages > 1;
    },
    statisticsGroups() {
      return webmasterStatisticsGroups;
    },
    limits() {
      return limits;
    },
    offset() {
      return this.filters.page * this.filters.limit - this.filters.limit;
    },
    isEmptyDate() {
      return this.dates[0] === null;
    },
    fields() {
      if (this.roles.includes('ROLE_STATISTIC')) {
        return [
          { key: 'group', label: this.$t('statistics.group') },
          { key: 'clicks', label: this.$t('statistics.clicks') },
          { key: 'cpa', label: this.$t('statistics.cpa') },
          { key: 'cpc', label: this.$t('statistics.cpc') },
          { key: 'cpl', label: this.$t('statistics.cpl') },
          { key: 'fd_count', label: this.$t('statistics.fd_count') },
          { key: 'imps', label: this.$t('statistics.imps') },
          { key: 'money', label: this.$t('statistics.money') },
          { key: 'regs', label: this.$t('statistics.regs') },
          { key: 'u_clicks', label: this.$t('statistics.u_clicks') },
          { key: 'u_imps', label: this.$t('statistics.u_imps') },
        ];
      } else {
        return [
          { key: 'group', label: this.$t('statistics.group') },
          { key: 'clicks', label: this.$t('statistics.clicks') },
          { key: 'imps', label: this.$t('statistics.imps') },
          { key: 'money', label: this.$t('statistics.money') },
          { key: 'u_clicks', label: this.$t('statistics.u_clicks') },
          { key: 'u_imps', label: this.$t('statistics.u_imps') },
        ];
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.filters.page = Number(route.query.page);
        }
      },
    },
    siteList(sites) {
      this.setSelectedValues(sites, 'site_ids');
    },
    blockList(blocks) {
      this.setSelectedValues(blocks, 'widget_ids');
    },
  },
  mounted() {
    if (this.$route.query.date_start) {
      this.setDatesFromParam();
    } else {
      this.dates = this.setInitialDate();
    }

    this.GET_SITES_LIST({ limit: filtersDefaultLimit });
    this.GET_BLOCKS_LIST({ limit: filtersDefaultLimit, site_ids: null });
    this.fetchStatistics();
  },
  methods: {
    ...mapActions('statistics', [GET_WEBMASTER_STATISTICS]),
    ...mapActions('sites', [GET_SITES_LIST]),
    ...mapActions('blocks', [GET_BLOCKS_LIST]),
    setInitialDate() {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return [date, new Date()];
    },
    setDatesFromParam() {
      this.dates = [new Date(this.$route.query.date_start), new Date(this.$route.query.date_end)];
    },
    changePage(page) {
      this.filters.page = page;
      this.fetchStatistics();
    },
    searchReset() {
      this.dates = this.setInitialDate();
      this.filters = {
        limit: limits[0].value,
        group_by: webmasterStatisticsGroups[0].value,
        date_start: this.dates[0],
        date_end: this.dates[1],
        site_ids: '',
        widget_ids: '',
        page: 1,
      };

      this.fetchStatistics();
    },
    writeQueryParams() {
      const appliedParams = { ...this.filters };

      if (this.dates.length > 0) {
        appliedParams.date_start = format(Date.parse(this.dates[0]), 'yyyy-MM-dd');
        appliedParams.date_end = format(Date.parse(this.dates[1]), 'yyyy-MM-dd');
      }

      if (appliedParams.site_ids && Array.isArray(appliedParams.site_ids)) {
        appliedParams.site_ids = appliedParams.site_ids.map((el) => Number(el.value)).join(',');
      }

      if (appliedParams.widget_ids && Array.isArray(appliedParams.widget_ids)) {
        appliedParams.widget_ids = appliedParams.widget_ids.map((el) => Number(el.value)).join(',');
      }

      for (const key in appliedParams) {
        if (appliedParams[key] === '') {
          delete appliedParams[key];
        }
      }

      this.$router.push({ query: appliedParams }).catch(() => {});

      return appliedParams;
    },
    onSubmit() {
      this.filters.page = 1;
      this.fetchStatistics();
    },
    setSelectedValues(list, field) {
      const selectedItems = [];
      const filter = this.filters[field];

      if (filter.length) {
        filter.split(',').forEach((el) => {
          list.forEach((item) => {
            if (Number(el) === item.value) {
              selectedItems.push(item);
            }
          });
        });
      }

      if (selectedItems.length) {
        this.filters = { ...this.filters, [field]: selectedItems };
      }
    },
    fetchStatistics() {
      this.writeQueryParams();
      const query = { ...this.$route.query };

      if (query?.site_ids) {
        query.site_ids = query.site_ids.split(',');
      }

      if (query?.widget_ids) {
        query.widget_ids = query.widget_ids.split(',');
      }

      this.GET_WEBMASTER_STATISTICS({ offset: this.offset, ...query });
      this.selectedLimit = this.filters.limit;
    },
  },
};
</script>
