const adminStatisticsGroups = [
  {
    value: 'day',
    label: 'day',
    type: 'date',
  },
  {
    value: 'webmaster',
    label: 'webmaster',
  },
  {
    value: 'advertiser',
    label: 'advertiser',
  },
  {
    value: 'site',
    label: 'site',
  },
  {
    value: 'creative',
    label: 'creative',
  },
];

const webmasterStatisticsGroups = [
  {
    value: 'day',
    label: 'day',
    type: 'date',
  },
  {
    value: 'site',
    label: 'site',
  },
  {
    value: 'widget',
    label: 'widget',
  },
];

const advertiserStatisticsGroups = [
  {
    value: 'day',
    label: 'day',
    type: 'date',
  },
  {
    value: 'campaign',
    label: 'campaign',
  },
  {
    value: 'creative',
    label: 'creative',
  },
  {
    value: 'format',
    label: 'format',
  },
  {
    value: 'webmaster',
    label: 'webmaster',
  },
  {
    value: 'site',
    label: 'site',
  },
];

export { adminStatisticsGroups, webmasterStatisticsGroups, advertiserStatisticsGroups };
